<template>
	<div id="data-sync-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Sincronizzazione dati
			</h1>
		</div>
		<div class="card mb-3">
			<div class="card-body">
				Caricamento in corso...

				<div class="progress mt-1">
					<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
						aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import StampingsRepository from '@/repositories/stampings-repository';
import OrdersRepository from '@/repositories/orders-repository';
import UsersRepository from '@/repositories/users-repository';

export default {
	name: "DataSyncView",

	async mounted() {
		try {
			await StampingsRepository.syncAll();

			await Promise.all([
				UsersRepository.downloadData(),
				StampingsRepository.downloadMissingData(),
				OrdersRepository.downloadData(),
			]);
		} catch (error) {
			console.log(error);
		} finally {
			await this.$router.replace({ name: "home.index" });
		}
	}
}

</script>