export default {
	get baseUrl() {
		let currentUrl = window.location.href

		let baseUrl = "";

		if (currentUrl.indexOf("localhost") !== -1) {
			baseUrl = "http://localhost/api/v1";
		} else if (currentUrl.indexOf("192.168.") !== -1) {
			baseUrl = "http://" + window.location.hostname + "/api/v1";
		} else if (currentUrl.indexOf("staging") !== -1) {
			baseUrl = "https://staging.timbratore.zato.servonet.it/api/v1";
		} else {
			baseUrl = "https://timbratore.zato.servonet.it/api/v1";
		}

		return baseUrl;
	}
};