import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import settings from './settings'
import MainHeader from '@/components/MainHeader.vue'
import axios from 'axios'
import localForage from 'localforage'
import vSelect from "vue-select"
import { extendPrototype } from 'localforage-startswith'

import "vue-select/dist/vue-select.css"

Vue.config.productionTip = false

Vue.component("MainHeader", MainHeader);
Vue.component('v-select', vSelect);

axios.defaults.baseURL = settings.baseUrl;

axios.interceptors.request.use(
	async request => {

		const user = await localForage.getItem("USER");

		request.headers.Authorization = "Bearer " + user?.token

		return Promise.resolve(request);
	}, error => Promise.resolve(error)
);

// Add a response interceptor to handle the common errors
axios.interceptors.response.use(response => response,
	error => {
		let message = "";

		if (error.code == "ERR_NETWORK") { // We are offline
			message = "Si è verificato un errore durante la richiesta; il dispositivo potrebbe essere offline.";
		} else if (error.response.data.errors) {
			message = error.response.data.errors.join("\n");
		} else {
			message = "Si è verificato un errore durante la richiesta."
		}

		alert(message);

		return Promise.reject(error)
	}
);

// Configure our database name
localForage.config({
	name: "zato_gestione_timbrature",
})

// Add startsWith to localForage
extendPrototype(localForage)

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
