<template>
	<div id="login-view" class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-lg-6 col-xl-4">
				<h1>Login</h1>

				<form action="" method="POST" @submit.prevent="performLogin()">
					<div class="mb-3">
						<label for="username" class="form-label">Username</label>
						<input type="text" class="form-control" name="username" id="username" v-model="username"
							placeholder="Username" required>
					</div>
					<div class="mb-3">
						<label for="password" class="form-label">Password</label>
						<input type="password" class="form-control" name="password" id="password" v-model="password"
							placeholder="Password" required>
					</div>
					<button class="w-100 btn btn-primary" type="submit" :disabled="loading">Accedi</button>
				</form>

				<p class="text-center text-muted mt-4">
					<em>Copyright &copy; 2024 Servonet Srl - Versione beta</em>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import AuthRepository from '@/repositories/auth-repository';

export default {
	name: 'LoginView',

	data() {
		return {
			username: "",
			password: "",
			loading: false
		};
	},

	methods: {
		async performLogin() {
			this.loading = true;

			try {
				await AuthRepository.login(this.username, this.password);

				await this.$router.replace({ name: "data-sync.index" });
			} finally {
				this.loading = false;
			}
		}
	}
}
</script>