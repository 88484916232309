<template>
	<div class="container">
		<div class="shadow rounded p-3 mb-4" id="stamping-button">
			<div v-if="createFormLoading" class="d-flex justify-content-center align-items-center">
				<span class="spinner-border" role="status"></span>
				<h3 class="mb-0">Loading...</h3>
			</div>
			<div v-else>
				<div class="input-group mb-3">
					<span class="input-group-text">Commessa</span>
					<v-select :options="ordersOptions" v-model="currentOrderOption" class="form-control"></v-select>
				</div>

				<div class="input-group mb-3">
					<span class="input-group-text">Utenti</span>
					<v-select :options="usersOptions" v-model="currentUserOptions" class="form-control"
						multiple></v-select>
				</div>

				<div class="form-floating mb-3">
					<textarea name="notes" class="form-control" id="notes" placeholder="Note" rows="4" v-model="notes"
						maxlength="500"></textarea>
					<label for="notes">Note</label>
				</div>

				<button class="btn w-100 p-3 fs-3 btn-success" type="button" @click="startTimer()"
					:disabled="disabledButton">
					<span v-if="disabledButton">Loading...</span>

					<span v-if="enabledButton">Avvia</span>
				</button>
			</div>
		</div>

		<div v-if="listLoading" class="d-flex justify-content-center align-items-center">
			<span class="spinner-border" role="status"></span>
			<h3 class="mb-0">Loading...</h3>
		</div>
		<div v-else class="table-responsive shadow rounded pt-2">
			<div class="float-start p-2">
				<button type="submit" class="btn btn-danger" @click="endTimers">
					<i class="fa fa-stop"></i> Ferma timbrature selezionate
				</button>
			</div>
			<table class="table table-striped table-bordered table-hover mb-0">
				<thead>
					<tr>
						<th width="1%">
							<input type="checkbox" @click="toggleCheckboxes($event)"
								data-target=".stamping-uuid-checkbox">
						</th>
						<th class="text-nowrap" width="1%">Utente</th>
						<th>Timer</th>
						<th>Commessa</th>
						<th class="text-center">Note</th>
						<th class="text-center" width="1%">Azioni</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="stamping in   stampings  ">
						<td class="text-center" width="1%">
							<input type="checkbox" class="stamping-uuid-checkbox" :value="stamping.uuid"
								v-model="stampingsUuids">
						</td>
						<td>
							{{ currentStampingUser(stamping) }}
						</td>
						<td class="text-nowrap">
							{{ getDateTimeLocal(stamping) }}
							<br>
							<span :id="stamping.uuid">{{ currentStampingTimerText(stamping) }}</span>
						</td>
						<td>
							{{ currentStampingOrder(stamping) }}
						</td>
						<td class="text-nowrap text-center" width="1%">
							<button class="btn"
								:class="{ 'btn-info': stamping.notes.length > 0, 'btn-secondary': stamping.notes.length == 0 }"
								@click="openModal($event)" :data-target="'#' + getStampingNotesModalId(stamping)">
								Note
							</button>

							<div class="modal visually-hidden" :id="getStampingNotesModalId(stamping)">
								<div class="modal-dialog">
									<div class="modal-content">
										<div class="modal-header">
											<h5 class="modal-title">Note</h5>
											<button type="button" class="btn-close"
												@click="closeModal($event)"></button>
										</div>
										<div class="modal-body">
											<textarea class="form-control" @input="notesHandler($event, stamping)"
												rows="3">{{ stamping.notes }}</textarea>
										</div>
									</div>
								</div>
							</div>

						</td>
						<td class="text-center" width="1%">
							<button class="btn btn-danger" @click="endTimer(stamping)">
								<i class="fa fa-stop"></i>
							</button>
						</td>
					</tr>
					<tr v-if="stampings.length == 0">
						<td colspan="6">Nessun elemento disponibile</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import StampingsRepository from '@/repositories/stampings-repository';
import OrdersRepository from '@/repositories/orders-repository';
import UsersRepository from '@/repositories/users-repository';
import DatetimeUtils from '@/utils/datetime-utils';
import AuthRepository from '@/repositories/auth-repository';

export default {
	name: 'StampingButton',

	data() {
		return {
			timerSeconds: 0,
			notesTimerId: null,

			createFormLoading: false,
			listLoading: false,
			currentUser: null,
			notes: "",
			usersWithUserNumber: [],
			orders: [],
			disabledButton: false,
			currentUserOptions: [],
			currentOrderOption: null,
			stampings: [],
			timers: {},
			stampingsUuids: [],
		}
	},

	async mounted() {
		try {
			this.createFormLoading = true;
			this.listLoading = true;

			[this.orders, this.usersWithUserNumber, this.currentUser] = await Promise.all([
				OrdersRepository.getAll(),
				UsersRepository.getAll(),
				AuthRepository.currentUser(),
				this.loadNotCloseStampings()
			]);

			this.currentUserOptions = [await this.loadCurrentUserOption(this.currentUser.id)];
		} catch (error) {
			console.error(error);
		} finally {
			this.createFormLoading = false;
			this.listLoading = false;
		}
	},

	computed: {
		currentStampingExists() {
			return this.currentStamping != null;
		},

		currentStampingNotExists() {
			return !this.currentStampingExists;
		},

		enabledButton() {
			return !this.disabledButton;
		},

		ordersOptions() {
			return this.orders.map(order => {
				const registryName = order.registry?.name || "";

				return {
					label: (order.external_zato_id + " - " + registryName).trim(),
					id: order.id
				}
			})
		},

		usersOptions() {
			return this.usersWithUserNumber.map(user => {
				return {
					label: user.user_number + " " + user.username,
					id: user.id
				}
			})
		},

		currentStampingNotes() {
			return this.currentStampingExists ? this.currentStamping.notes : "";
		}
	},

	methods: {
		currentStampingTimerText(stamping) {
			if (this.timers[stamping.uuid] === undefined) {
				return "00:00:00";
			}

			return this.timerText(this.timers[stamping.uuid].timerSeconds);
		},

		timerText(timerSeconds) {
			return DatetimeUtils.niceSeconds(timerSeconds)
		},

		getDateTimeLocal(stamping) {
			return DatetimeUtils.getDateTimeLocal(stamping.started_at_utc, stamping.started_at_timezone)
		},

		currentStampingOrder(stamping) {
			let orderExternalZatoId = stamping.order?.external_zato_id || "";
			let registryName = stamping.order?.registry?.name || "";

			if (!orderExternalZatoId && !registryName) {
				const order = this.orders.find(order => order.id == stamping.order_id);

				orderExternalZatoId = order?.external_zato_id || "";
				registryName = order?.registry?.name || "";
			}

			return (orderExternalZatoId + " " + registryName).trim();
		},

		currentStampingUser(stamping) {
			let username = stamping?.user.username || "";

			if (!username) {
				const user = this.usersWithUserNumber.find(user => user.id === stamping.user_id)

				username = user?.username || "";
			}

			return username;
		},

		async startTimer() {
			let alreadyCreateStamping = false;
			const usersIds = this.stampings.map(stamping => stamping.user_id);

			const orderId = this.currentOrderOption?.id || null;

			if (orderId == null) {
				alert("La commessa deve essere obbligatoria");
				return;
			}

			this.listLoading = true;
			this.disabledButton = true;

			for (const userOption of this.currentUserOptions) {
				try {
					const params = {
						user_id: userOption.id || null,
						order_id: this.currentOrderOption?.id || null,
						notes: this.notes,
					};

					if (usersIds.includes(params.user_id)) {
						alreadyCreateStamping = true;
						continue;
					}

					const stamping = await StampingsRepository.create(params);

					this.stampings.push(stamping);

					this.timerInit(stamping);
				} catch (error) {
					console.error(error);
					alert("C'è stato un errore durante l'apertura della timbratura");
				}
			}

			if (alreadyCreateStamping) {
				alert("Non è possibile creare nuove timbrature per utenti che hanno già una timbratura aperta");
			}

			this.currentOrderOption = null;
			this.disabledButton = false;
			this.listLoading = false;
		},

		async endTimer(stamping) {
			try {
				this.listLoading = true;

				await StampingsRepository.close(stamping.uuid);

				clearInterval(this.timers[stamping.uuid].intervalReference);
				delete this.timers[stamping.uuid];

				await this.loadNotCloseStampings();
			} catch (error) {
				console.error(error);
				alert("C'è stato un errore durante la chiusura della timbratura");
			} finally {
				this.listLoading = false;
			}
		},

		async endTimers() {
			this.listLoading = true;

			for (const stampingUuid of this.stampingsUuids) {
				try {
					await StampingsRepository.close(stampingUuid);

					clearInterval(this.timers[stampingUuid].intervalReference);
					delete this.timers[stampingUuid];
				} catch (error) {
					console.error(error);
					alert("C'è stato un errore durante la chiusura della timbratura");
				}
			}

			await this.loadNotCloseStampings();
			this.listLoading = false;
		},

		timerInit(stamping) {
			let timerSeconds = DatetimeUtils.getUTCSeconds() - stamping.started_at_utc;

			this.timers[stamping.uuid] = { timerSeconds: timerSeconds };

			this.timers[stamping.uuid].intervalReference = setInterval(() => {
				this.timers[stamping.uuid].timerSeconds++;

				const HTMLElement = document.getElementById(stamping.uuid);

				if (HTMLElement) HTMLElement.textContent = this.timerText(this.timers[stamping.uuid].timerSeconds);
			}, 1000);
		},

		toggleCheckboxes(e) {
			if (e.target.checked) {
				this.stampingsUuids = this.stampings.map(stamping => stamping.uuid);
			} else {
				const targetClassName = e.target.dataset.target;

				for (const element of document.querySelectorAll(targetClassName)) {
					if (element.checked) element.click();
				}
			}
		},

		async loadNotCloseStampings() {
			this.stampings = await StampingsRepository.notCloseStampings();

			for (const stamping of this.stampings) {
				if (this.timers[stamping.uuid] === undefined) {
					this.timerInit(stamping);
				}
			}
		},

		async loadCurrentOrderOption(orderId) {
			const currentOrder = (await OrdersRepository.getAll()).find(order => order.id == orderId) || null;

			if (!currentOrder) {
				return null;
			}

			const registryName = currentOrder.registry?.name || "";

			const currentOrderLabel = currentOrder ? currentOrder.external_zato_id + " - " + registryName : "";
			const currentOrderId = currentOrder?.id || null;

			return { label: currentOrderLabel, id: currentOrderId };
		},

		async loadCurrentUserOption(userId) {
			const foundUser = await UsersRepository.find(userId);

			const foundUserLabel = ((foundUser?.user_number || "") + " " + (foundUser?.username || "")).trim();

			return { label: foundUserLabel, id: foundUser?.id || null };
		},

		async ordersSelectHandler(option) {
			try {
				this.disabledButton = true;

				this.currentOrderOption = option;

				if (this.currentStampingExists) {
					this.currentStamping.order_id = this.currentOrderOption?.id || null;
					this.currentStamping = await StampingsRepository.update(this.currentStamping);
				}
			} catch (error) {
				console.error(error);
				alert("C'è stato un errore durante l'aggiornamento della timbratura");
			} finally {
				this.disabledButton = false;
			}
		},

		notesHandler(event, stamping) {
			if (this.notesTimerId) {
				clearTimeout(this.notesTimerId)
			}

			this.notesTimerId = setTimeout(() => {
				try {
					console.log(event.target.value);

					stamping.notes = event.target.value;

					StampingsRepository.update(stamping).then(currentStamping => stamping = currentStamping);
				} catch (error) {
					console.error(error);
					alert("C'è stato un errore durante l'aggiornamento della timbratura");
				} finally {
					this.disabledButton = false;
					this.notesTimerId = null;
				}
			}, 1000);
		},

		openModal(event) {
			const targetQuery = event.target.dataset.target;

			const modal = document.querySelector(targetQuery);

			modal.classList.remove("visually-hidden");
		},

		closeModal(event) {
			const target = event.target;

			const modal = target.closest("div.modal");

			modal.classList.add("visually-hidden");
		},

		getStampingNotesModalId(stamping) {
			return "stamping-notes-modal-" + stamping.uuid
		},
	},
}
</script>

<style>
.modal {
	display: block;
	background-color: rgba(0, 0, 0, 0.5);
}
</style>