import localforage from "localforage";
import axios from "axios";

/**
 * @typedef User
 *
 * @type {object}
 * @property {string} username
 * @property {string} name
 * @property {string} surname
 * @property {string} token
 * @property {string} user_number
 */

export default class AuthRepository {
	static namespace = "USER";

	/**
	 * Performs login
	 *
	 * @async
	 * @param {string} username
	 * @param {string} password
	 *
	 * @return {Promise<void>}
	 */
	static async login(username, password) {
		const formData = new FormData();

		formData.append("username", username)
		formData.append("password", password)

		const response = await axios.post("/auth/login", formData, {
			headers: { "Content-Type": "application/x-www-form-urlencoded" }
		});

		const user = {
			id: response.data?.id || null,
			username: response.data?.username || "",
			name: response.data?.name || "",
			surname: response.data?.surname || "",
			token: response.data?.token || "",
			user_number: response.data?.user_number || "",
		};

		localforage.setItem(this.namespace, user);
	}

	/**
	 * Get Current User
	 *
	 * @return {Promise<?User>}
	 */
	static async currentUser() {
		return (await localforage.getItem(this.namespace)) || null;
	}

	static async logout() {
		await localforage.removeItem(this.namespace);
	}
}