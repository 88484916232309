import axios from "axios";
import localforage from "localforage";


/**
 * @typedef Registry
 *
 * @property {int} id
 * @property {string} name
 * @property {string} vat_number
 */

/**
 * @typedef Order
 *
 * @type {object}
 * @property {int} id
 * @property {string} name
 * @property {string} external_zato_id
 * @property {null | int} registry_id
 * @property {null | Registry} registry
 */
export default class OrdersRepository {
	static namespace = "ORDERS";

	/**
	 * Get all orders
	 *
	 * @returns {Promise<Order[]>}
	 */
	static async getAll() {
		return (await localforage.getItem(this.namespace)) || [];
	}

	/**
	 * Download orders
	 *
	 */
	static async downloadData() {
		const response = await axios.get(`/orders`);

		const orders = response.data;

		localforage.setItem(this.namespace, orders);
	}
}