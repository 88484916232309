import axios from "axios";
import localforage from "localforage";

export default class UsersRepository {
	static namespace = "USERS";

	static async getAll() {
		return (await localforage.getItem(this.namespace)) || [];
	}

	static async getUsersWithUserNumber() {
		return (await this.getAll()).filter(user => user.user_number);
	}

	static async downloadData() {
		const response = await axios.get("/users");

		const users = response.data.map(user => {
			return {
				id: user.id,
				user_number: user.user_number,
				username: user.username,
			}
		})

		localforage.setItem(this.namespace, users);
	}

	static async find(userId) {
		return (await this.getAll()).find(user => user.id == userId) || null;
	}
}