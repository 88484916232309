import DatetimeUtils from "@/utils/datetime-utils";
import RandomUtils from "@/utils/random-utils";
import axios from "axios";
import localforage from "localforage";
import AuthRepository from "./auth-repository";
import OrdersRepository from "./orders-repository";
import UsersRepository from "./users-repository";

/**
 * @typedef Stamping
 *
 * @type {object}
 * @property {?int} id
 * @property {string} uuid
 * @property {int} started_at_utc
 * @property {?int} finished_at_utc
 * @property {string} started_at_timezone
 * @property {string} finished_at_timezone
 * @property {string} order_name
 * @property {int} user_id
 * @property {string} notes
 * @property {boolean} synced
 * @property {?int} order_id
 * @property {?object} order
 */
export default class StampingsRepository {
	static startNamespace = "STAMPINGS";

	static async namespace() {
		const token = (await AuthRepository.currentUser())?.token || "";

		return this.startNamespace + "_" + token;
	}

	/**
	 * Get all stampings
	 *
	 * @returns {Promise<Stamping[]>}
	 */
	static async getAll() {
		return (await localforage.getItem(await this.namespace())) || [];
	}

	/**
	 *
	 * @returns {Promise<Stamping>}
	 */
	static async create(params) {
		/** @type {Stamping} stamping */
		const stamping = {
			id: null,
			uuid: RandomUtils.getUUID(),
			started_at_utc: DatetimeUtils.getUTCSeconds(),
			finished_at_utc: null,
			started_at_timezone: DatetimeUtils.getTimezone(),
			finished_at_timezone: "",
			order_id: params?.order_id || null,
			user_id: params?.user_id || null,
			order: null,
			notes: params?.notes || "",
			synced: false,
		}

		if (stamping.order_id) {
			const orders = await OrdersRepository.getAll()

			stamping.order = orders.find(order => order.id == stamping.order_id) || null;
		}

		if (stamping.user_id) {
			const users = await UsersRepository.getAll()

			stamping.user = users.find(user => user.id == stamping.user_id) || null;
		}

		if (navigator.onLine) {
			const responseData = await this.createToRemote(stamping);
			stamping.id = responseData?.id || null;
		}

		/** @type {Stamping[]} stampings */
		const stampings = await this.getAll();
		stampings.push(stamping);

		await localforage.setItem(await this.namespace(), stampings);

		return stamping;
	}

	/**
	 *
	 * @param {Stamping} stamping
	 *
	 * @returns {Promise<?Stamping>} stamping
	 */
	static async createToRemote(stamping) {
		const response = await axios.post("/stampings/create", stamping);

		return response.data;
	}

	/**
	 *
	 * @param {string} uuid
	 * @param {Object} params
	 *
	 * @returns {Promise<?Stamping>} stamping
	 */
	static async close(uuid) {
		const stampings = await this.getAll();

		const stamping = stampings.find(stamping => stamping.uuid == uuid);

		if (navigator.onLine && stamping.id === null) {
			const responseData = await this.createToRemote(stamping);
			stamping.id = responseData?.id || null;
		}

		if (stamping.finished_at_utc == null && stamping.finished_at_timezone == "") {
			stamping.finished_at_utc = DatetimeUtils.getUTCSeconds();
			stamping.finished_at_timezone = DatetimeUtils.getTimezone();
		}

		if (navigator.onLine && stamping.id && !stamping.synced) {
			await this.closeToRemote(stamping);
			stamping.synced = true;
		}

		await localforage.setItem(await this.namespace(), stampings);

		return stamping;
	}


	static async closeToRemote(stamping) {
		const response = await axios.post(`/stampings/${stamping.id}/close`, stamping);

		return response.data;
	}

	/**
	 * @param {Stamping} stamping
	 *
	 * @returns {Promise<Stamping>}
	 */
	static async update(stamping) {
		const stampings = await this.getAll();

		if (stamping.order_id == null) {
			stamping.order = null;
		} else {
			const orders = await OrdersRepository.getAll()
			stamping.order = orders.find(order => order.id == stamping.order_id) || null;
		}

		if (navigator.onLine && stamping.id === null) {
			const responseData = await this.createToRemote(stamping);

			stamping.id = responseData?.id || null;
		} else if (navigator.onLine && stamping.id) {
			const responseData = await this.updateToRemote(stamping);

			stamping.order = responseData.order;
		}

		const stampingIndex = stampings.findIndex(currentStamping => currentStamping.uuid == stamping.uuid);
		stampings[stampingIndex] = stamping;

		await localforage.setItem(await this.namespace(), stampings);

		return stamping;
	}


	static async updateToRemote(stamping) {
		const response = await axios.post(`/stampings/${stamping.id}/update`, stamping);

		return response.data;
	}

	/**
	 * Sync all stampings
	 *
	 */
	static async syncAll() {
		const stampings = await this.getAllUnsynced();

		for (const stamping of stampings) {
			if (navigator.onLine && stamping.id === null) {
				const responseData = await this.createToRemote(stamping);
				stamping.id = responseData?.id || null;
			}

			if (navigator.onLine && stamping.id && !stamping.synced && stamping.finished_at_utc) {
				await this.closeToRemote(stamping);
				stamping.synced = true;
			}
		}

		localforage.setItem(await this.namespace(), stampings);
	}

	/**
	 * Get all not close stampings
	 *
	 * @returns {Promise<Stamping[]>}
	 */
	static async notCloseStampings() {
		await this.downloadMissingData();

		const stampings = await this.getAll();

		return stampings.filter(stamping => stamping.finished_at_utc === null);
	}

	/**
	 * Get first not close stamping
	 *
	 * @returns {Promise<?Stamping>}
	 */
	static async notCloseStamping() {
		const notCloseStampings = await this.notCloseStampings();

		if (notCloseStampings.length == 0) {
			return null;
		}

		return notCloseStampings[0];
	}

	/**
	 * Get All unsynced Stampings
	 *
	 * @returns {Promise<Stamping[]>}
	 */
	static async getAllUnsynced() {
		const stampings = await this.getAll();

		return stampings.filter(stamping => !stamping.synced);
	}


	static async downloadMissingData() {
		const response = await axios.get(`/stampings`);

		const responseData = response.data;

		const localStampings = await this.getAll();

		for (const stampingRaw of responseData) {
			const currentStamping = localStampings.find(stamping => stampingRaw.id == stamping.id);

			if (currentStamping === undefined) {
				stampingRaw.uuid = RandomUtils.getUUID();
				stampingRaw.synced = Boolean(stampingRaw.finished_at_utc);

				stampingRaw.started_at_utc = DatetimeUtils.UTCtoTimestamp(stampingRaw.started_at_utc);
				stampingRaw.finished_at_utc = stampingRaw.finished_at_utc ? DatetimeUtils.UTCtoTimestamp(stampingRaw.finished_at_utc) : null;

				localStampings.push(stampingRaw);
				continue;
			}

			if (stampingRaw.finished_at_utc && currentStamping.finished_at_utc === null) {
				currentStamping.finished_at_utc = DatetimeUtils.UTCtoTimestamp(stampingRaw.finished_at_utc);
				currentStamping.finished_at_timezone = stampingRaw.finished_at_timezone;
			}
		}

		const namespace = await this.namespace();

		localStampings.sort((a, b) => a.id - b.id)

		await localforage.setItem(namespace, localStampings);
	}
}