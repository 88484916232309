<template>
	<div id="home-view" class="container">
		<stampings></stampings>

		<div class="container">
			<hr>

			<p class="text-center">
				Connesso come <span class="text-primary">{{ username }}</span>
			</p>

			<button class="btn btn-danger mb-3 w-100" type="button" @click="performLogout()">
				Logout
			</button>
		</div>
	</div>
</template>

<script>
import AuthRepository from '@/repositories/auth-repository';
import Stampings from '@/components/Stampings.vue'

export default {
	name: 'HomeView',

	components: {
		Stampings,
	},

	data() {
		return {
			user: null
		}
	},

	async mounted() {
		this.user = await AuthRepository.currentUser();
	},

	computed: {
		username() {
			if (this.user == null) {
				return "";
			}

			return this.user.username
				? this.user.username
				: `${this.user.name} ${this.user.surname}`.trim();
		}
	},

	methods: {
		async performLogout() {
			await AuthRepository.logout();
			await this.$router.replace({ name: "auth.login" });
		}
	},
}
</script>