import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import HomeView from '@/views/HomeView.vue'
import DataSyncView from '@/views/DataSyncView.vue'
import guestMiddleware from '@/middlewares/guest-middleware'
import authMiddleware from '@/middlewares/auth-middleware'

Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'auth.login',
		component: LoginView,
		beforeEnter: guestMiddleware,
	},
	{
		path: '/',
		name: 'home.index',
		component: HomeView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/data-sync',
		name: 'data-sync.index',
		component: DataSyncView,
		beforeEnter: authMiddleware,
	},
]

const router = new VueRouter({
	routes
})

export default router
