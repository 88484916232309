<template>
	<header>
		<nav class="navbar navbar-expand-lg mb-3" :class="{
			'bg-warning navbar-dark': isDevelopmentEnvironment,
			'bg-primary navbar-light': isProductionEnvironment,
		}">
			<div class="container">
				<router-link to="/" class="navbar-brand">
					Zato Timbrature
				</router-link>
				<ul class="navbar-nav">
					<li class="nav-item" v-if="isDevelopmentEnvironment">
						<a href="#" class="nav-link text-white">
							DEVELOPMENT
						</a>
					</li>
					<li class="nav-item" v-if="isOffline">
						<a href="#" class="nav-link text-yellow">
							&#9888;
							Sei offline
						</a>
					</li>
				</ul>
			</div>
		</nav>
	</header>
</template>

<script>

export default {
	name: 'MainHeader',

	data() {
		return {
			isOnline: navigator.onLine
		}
	},

	mounted() {
		window.addEventListener("online", () => this.isOnline = true)
		window.addEventListener("offline", () => this.isOnline = false)
	},

	computed: {
		isOffline() {
			return !this.isOnline;
		},

		isProductionEnvironment() {
			return location.hostname === "app.timbratore.zato.servonet.it";
		},

		isDevelopmentEnvironment() {
			return !this.isProductionEnvironment;
		}
	}

}
</script>