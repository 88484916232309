<template>
	<div id="app" :class="{ 'has-available-updates': hasAvailableUpdates }">
		<MainHeader></MainHeader>

		<div class="container" id="panel-updates-available" v-if="hasAvailableUpdates">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="text-center p-4">
						Nuova versione disponibile.
					</h4>

					<button class="btn btn-success w-100" type="button" @click="performUpdate()">
						Aggiorna
					</button>
				</div>
			</div>
		</div>
		<router-view v-else />
	</div>
</template>

<script>

export default {
	data() {
		return {
			isRefreshingServiceWorker: false,
			isUpdating: false,
			hasAvailableUpdates: false,
			serviceWorkerRegistration: null,
			isOnline: true,
		}
	},

	async mounted() {
		window.addEventListener("online", () => this.isOnline = true)

		window.addEventListener("offline", () => this.isOnline = false)

		if (!this.hasAvailableUpdates) {
			await this.$router.replace({ name: "data-sync.index" });
		}
	},

	created() {
		document.addEventListener("serviceWorkerUpdated", this.showUpdatesAvailableUI, { once: true })

		navigator.serviceWorker.addEventListener("controllerchange", async () => {
			if (this.isRefreshingServiceWorker) {
				return
			}

			this.isRefreshingServiceWorker = true;

			window.location.reload();
		})
	},

	methods: {
		showUpdatesAvailableUI(event) {
			this.serviceWorkerRegistration = event.detail
			this.hasAvailableUpdates = true
		},

		performUpdate() {
			this.hasAvailableUpdates = false

			if (!this.serviceWorkerRegistration || !this.serviceWorkerRegistration.waiting) {
				return
			}

			this.isUpdating = true
			this.serviceWorkerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
		},
	},

	watch: {
		async isOnline(newIsOnline, oldIsOnline) {
			if (newIsOnline && !oldIsOnline && !this.hasAvailableUpdates) {
				await this.$router.replace({ name: "data-sync.index" });
			}
		}
	}
}
</script>

<style>
#panel-updates-available {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10000;
	transform: translate(-50%, -50%);
}
</style>