export default class DatetimeUtils {

	/**
	 * Get current timestamp in UTC
	 *
	 * @returns {number}
	 */
	static getUTCSeconds() {
		return Math.floor(Date.now() / 1000);
	}

	/**
	 * Get current timestamp with Timezone
	 *
	 * @returns {number}
	 */
	static getSeconds() {
		const timezoneOffset = new Date().getTimezoneOffset();

		const seconds = (timezoneOffset * -1) * 60;

		return this.getUTCSeconds() + seconds;
	}

	/**
	 * Get Local Timezone
	 *
	 * @returns {number}
	 */
	static getTimezone() {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	}


	/**
	 * Convert datetimeUtc to timestamp
	 *
	 * @returns {number}
	 */
	static UTCtoTimestamp(datetimeUtc) {
		const splittedDatetime = datetimeUtc.split(" ");

		const date = splittedDatetime[0] || "";
		const time = splittedDatetime[1] || "";

		if (!date || !time) {
			return null;
		}

		const splittedDate = date.split("-");
		const splittedTime = time.split(":");

		const year = parseInt(splittedDate[0] || 0);
		const monthIndex = parseInt(splittedDate[1] || 0) - 1;
		const day = parseInt(splittedDate[2] || 0);

		const hours = parseInt(splittedTime[0] || 0);
		const minutes = parseInt(splittedTime[1] || 0);
		const seconds = parseInt(splittedTime[2] || 0);

		return Math.floor(Date.UTC(year, monthIndex, day, hours, minutes, seconds) / 1000);
	}

	static getDateTimeLocal(UTCSeconds, timezone) {
		const date = new Date(UTCSeconds * 1000);

		const dateTimeLocal = date.toLocaleString('it-IT', { timeZone: timezone });

		const splittedDateTime = dateTimeLocal.split(" ");
		const splittedDate = splittedDateTime[0].replace(",", "").split("/");
		const time = splittedDateTime[1];

		const day = splittedDate[0].padStart(2, '0');
		const month = splittedDate[1].padStart(2, '0');
		const year = splittedDate[2];

		return `${day}/${month}/${year} ${time}`;
	}


	static niceSeconds(seconds) {
		if (seconds === undefined || seconds === null) return "";

		seconds = parseInt(seconds, 10);
		let hours = Math.floor(seconds / 3600);
		let minutes = Math.floor((seconds - hours * 3600) / 60);

		seconds = seconds - minutes * 60 - hours * 3600;

		if (hours < 10) hours = '0' + hours;
		if (minutes < 10) minutes = '0' + minutes;
		if (seconds < 10) seconds = '0' + seconds;

		return hours + ':' + minutes + ':' + seconds;
	}

	static timeToSeconds(time) {
		const splittedTime = time.split(':');

		return parseInt(splittedTime[0]) * 3600 + parseInt(splittedTime[1]) * 60 + parseInt(splittedTime[2]);
	}
}