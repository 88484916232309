import AuthRepository from '@/repositories/auth-repository';
import { NavigationGuardNext, Route } from 'vue-router';

/**
 *
 * @param {Route} to
 * @param {Route} from
 * @param {NavigationGuardNext} next
 * @return {Promise<next>}
 */
export default async function (to, from, next) {
	const user = await AuthRepository.currentUser();

	if (user) {
		return next(from.path)
	}

	return next()
}